import React from 'react';
import s from './MobileFiltersModal.scss';
import classNames from 'classnames';
import {Filters} from '../../Filters/Filters';

import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';
import FocusTrap from 'focus-trap-react';

export interface IMobileFiltersModal extends IGalleryGlobalProps {
  className: string;
  clearFilters: Function;
  closeModal: Function;
  isOpen: boolean;
  shouldShowClearFilters: boolean;
  modalRef: React.RefObject<HTMLDivElement>;
  filtersBtnRef: React.RefObject<HTMLButtonElement>;
  modalHeaderId: string;
}

class MobileFiltersModalComp extends React.Component<IMobileFiltersModal> {
  public state = {
    activeTrap: this.props.isOpen,
  };

  public componentDidUpdate(prevProps) {
    if (this.props.globals.experiments.fixGalleryMobileFiltersFocus && prevProps.isOpen !== this.props.isOpen) {
      this.setState({activeTrap: this.props.isOpen});
      this.updateInertAttribute();
    }
  }

  public componentDidMount(): void {
    if (this.props.globals.experiments.fixGalleryMobileFiltersFocus) {
      this.updateInertAttribute();
    }
  }

  public updateInertAttribute() {
    const modalDiv = this.props.modalRef.current;
    if (this.props.isOpen) {
      modalDiv?.removeAttribute('inert');
    } else {
      modalDiv?.setAttribute('inert', '');
    }
  }

  public render() {
    const {isOpen, shouldShowClearFilters, clearFilters, closeModal} = this.props;
    const {isPreviewMode, experiments} = this.props.globals;

    const classnames = classNames({
      [s.modal]: true,
      [s.open]: isOpen,
      [s.preview]: isPreviewMode,
      [s.withFocusFix]: experiments.fixGalleryMobileFiltersFocus,
    });

    const filtersModal = (
      <div
        className={classnames}
        data-hook="mobile-filters-modal"
        tabIndex={-1}
        role={'dialog'}
        aria-labelledby={this.props.modalHeaderId}
        aria-modal={this.props.isOpen}
        ref={this.props.modalRef}>
        <Filters
          closeMobileFiltersModal={() => closeModal()}
          shouldShowClearFiltersButton={shouldShowClearFilters}
          clearFilters={clearFilters}
          modalHeaderId={this.props.modalHeaderId}
        />
      </div>
    );

    return this.props.globals.experiments.fixGalleryMobileFiltersFocus ? (
      <FocusTrap
        active={this.state.activeTrap}
        focusTrapOptions={{
          initialFocus: /* istanbul ignore next reason: tested in sled */ () => this.props.modalRef.current,
          fallbackFocus: () => this.props.modalRef.current,
          onDeactivate: () => closeModal(),
          returnFocusOnDeactivate: true,
          setReturnFocus: () => this.props.filtersBtnRef.current,
        }}>
        {filtersModal}
      </FocusTrap>
    ) : (
      filtersModal
    );
  }
}

export const MobileFiltersModal = withGlobals(MobileFiltersModalComp);
